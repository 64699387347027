<template>
  <section class="agent-buttons">
    <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">{{ $t("AgentClassic") }}</a>
    <a class="primary-button primary-button__new" :href="agentNew" target="_blank">{{ $t("AgentNew") }}</a>
  </section>
  <img  class="brand" src="../assets/images/logo.png" alt="wagerInternational">
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const agentClassic = "https://agents.betall.ag/";
      const agentNew = "https://adm.betall.ag/home/landing";
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
