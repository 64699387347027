<template>

  <header class="header">
    <div class="header-content max-container">
        <router-link to="/" class="header-content__container-logo">
            <brand-header :image="urlImage" :altImage="altImagen"/>
        </router-link>
    </div>
    
  </header>
</template>

<script>
  import BrandHeader from '@/components/brand-header.vue';


  export default {
    name: "HeaderComponent",
    components: {
      BrandHeader
    },
    data() {
      return {
        urlImage: require('../assets/images/logo.png'),
        altImage: 'wagerStore',
      }
    },
  };
</script>
