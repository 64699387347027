import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/sportsbook',
    name: 'sportsbook-langen',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/sportsbook-pt',
    name: 'sportsbook-langpt',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/apuestas-deportivas',
    name: 'sportsbook-langes',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/racebook',
    name: 'racebook-langen',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/racebook-pt',
    name: 'racebook-langpt',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/caballos',
    name: 'racebook-langes',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/live-betting',
    name: 'live-betting-langen',
    component: () => import('../pages/live-betting.vue'),  
  },
  {
    path: '/live-betting-pt',
    name: 'live-betting-langpt',
    component: () => import('../pages/live-betting.vue'),  
  },
  {
    path: '/apuestas-en-vivo',
    name: 'live-betting-langes',
    component: () => import('../pages/live-betting.vue'),  
  },
  {
    path: '/house-rules',
    name: 'house-rules-langen',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/house-rules-pt',
    name: 'house-rules-langpt',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/reglas-de-la-casa',
    name: 'house-rules-langes',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/sports-rules',
    name: 'sports-rules-langen',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/sports-rules-pt',
    name: 'sports-rules-langpt',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/reglas-deportivas',
    name: 'sports-rules-langes',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/same-game-parlay-rules',
    name: 'same-game-parlay-rules-langen',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
  {
    path: '/reglas-de-parlay-del-mismo-equipo',
    name: 'same-game-parlay-rules-langes',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
  {
    path: '/same-game-parlay-rules',
    name: 'same-game-parlay-rules-langpt',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router