<template>
  <footer class="footer">
    <div class="footer-content">
      <agent-button/>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn1" :key="index">
          <router-link @click="scrollToTop" :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="footer-content__rights">
      <p>{{ $t("Rights") }}</p>
    </div>
    <mobile-menu/>
  </footer>
</template>


<script>
  import AgentButton from "@/components/agent-button.vue"
  import MobileMenu from '@/components/mobile-menu.vue'
  import { useI18n } from 'vue-i18n';
  import { reactive, watch } from 'vue';
  export default {
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    name: "FooterComponent",
    components: {
      AgentButton,
      MobileMenu
    },
    setup() {
      const { t, locale  }  = useI18n();

      const routesColumn1= reactive( [
          {
            name       :  t("footerColum1.0.name"),
            route      :  t("footerColum1.0.route")
          },
          {
            name       :   t("footerColum1.1.name"),
            route      :   t("footerColum1.1.route")
          },
          {
            name       :   t("footerColum1.2.name"),
            route      :   t("footerColum1.2.route")
          },
          {
            name       :  t("footerColum1.3.name"),
            route      :  t("footerColum1.3.route")
          },
          {
            name       :   t("footerColum1.4.name"),
            route      :   t("footerColum1.4.route")
          },
          {
            name       :   t("footerColum1.5.name"),
            route      :   t("footerColum1.5.route")
          },
          
        ])
             // Observar los cambios de idioma y actualiza los slides
        const updateSlides = () => {
        routesColumn1.forEach((opcion, index) => {
          opcion.name = t(`footerColum1.${index}.name`);
          opcion.route = t(`footerColum1.${index}.route`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
      return {
        routesColumn1,
        
      }
    },
  };
</script>
